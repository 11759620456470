import React from 'react'

export default function Diary() {
  return (
    <div>
        <section style={{padding: '5% 15% 5%', textAlign: 'left'}}>
            <a href="https://ifesinsightsandadventures.blogspot.com/2022/03/what-does-it-mean-to-stand-up-for-whats.html">
        <h2>Diary entry #1:</h2> 
        <p>What does it mean to stand up for what's right? The story of two guys, a girl, and an Oscar award
        </p>
        </a>
        </section>
    </div>
  )
}
