// import '../App.css';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Main/>
      {/* <Footer/> */}
    </div>
  );
}

export default App;
